import React, {Component} from "react";
import {
    Backdrop,
    Container,
    CircularProgress,
} from "@mui/material";
import {
    Table as TableComponent,
    Filter as FilterComponent,
    DialogForm as DialogFormComponent
} from "./components";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import {
    DialogConfirmAction
} from "../../../../components";
import agent from "../../../../agent/agent";
import queryString from "query-string";
import moment from "moment";

const initialFilter = {
    "filter[services]": [],
    "filter[countries]": [],
    "filter[users]": [],
    "filter[provider_id]": "",

    "filter[price][gt]": "",
    "filter[price][lt]": "",

    "filter[created_at][gt]": null,
    "filter[created_at][lt]": null,

    "sort": ""
};

class GoPrices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            prices: [],
            countries: [],
            services: [],

            filter: {
                ...initialFilter
            },
            pagination: {
                page: 1,
                totalPage: 1
            },

            editFormId: null,

            isLoad: true,
            isShowBackdrop: false
        };

        this.apiController = null;
        this.refDialogForm = React.createRef();
        this.refDialogConfirmAction = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getPrices();

        // Получение списка доп данных
        await this.getCountry();
        await this.getService();
    }
    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        if (!!filter['filter[countries]'] && typeof filter['filter[countries]'] === "string") {
            filter['filter[countries]'] = (filter['filter[countries]'] || "").split(",")
        }
        if (!!filter['filter[services]'] && typeof filter['filter[services]'] === "string") {
            filter['filter[services]'] = (filter['filter[services]'] || "").split(",")
        }
        if (!!filter['filter[users]'] && typeof filter['filter[users]'] === "string") {
            filter['filter[users]'] = (filter['filter[users]'] || "").split(",")
        }

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }


    getPrices = async () => {
        if (this.apiController) {
            await this.apiController.abort();
        }
        this.setState({isLoad: true});

        const filter = this.getFilter();
        const apiController = new AbortController();
        this.apiController = apiController;
        const {data, headers} = await agent.get(`/smsgoapi/go-prices${filter}`, {
            signal: apiController.signal
        }).then((res) => {
            return {
                data: res.data || [],
                headers: res.headers
            }
        }).catch((err) => {
            return {data: [], headers: {}}
        });

        const pagination = {
            ...this.state.pagination,
            totalPage: headers?.["x-pagination-page-count"] || 1
        };
        this.setState({
            prices: data,
            pagination,
            isLoad: false
        });

    }
    getCountry = async () => {

        const data = await agent.get(`/api/country`).then((res) => {
            return res.data
        }).catch((err) => {
            return []
        });

        this.setState({
            countries: data
        })

    }
    getService = async () => {

        const data = await agent.get(`/api/service/get-all`).then((res) => {
            return res.data
        }).catch((err) => {
            return []
        });


        this.setState({
            services: data
        })

    }

    getFilter = () => {
        const {users} = this.props;
        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};

        let string = [
            `page=${pagination.page}`
        ];
        Object.keys(filter).map((key) => {
            if (filter[key] && filter[key].length > 0) {

                let value = filter[key];

                if (
                    key === "filter[created_at][gt]" ||
                    key === "filter[created_at][lt]"
                ) {
                    value = moment(value).format("YYYY-MM-DD HH:mm")
                }
                if (
                    key === "filter[price][gt]" ||
                    key === "filter[price][lt]"
                ) {
                    value = value.replace(/\D+/g, "");
                }

                string.push(`${key}=${value}`);

            }
        });

        window.history.replaceState(null, null, `/go-prices?${string.join("&")}`);

        return `?${string.join("&")}`
    }
    changeFilter = async (filter, isFastStart) => {

        await this.setState({filter});

        if (!isFastStart) {
            return null
        }

        await this.getPrices();

    }
    resetFilter = async () => {

        await this.setState({filter: initialFilter});

        await this.getPrices();

    }

    changePagination = async (pagination) => {
        await this.setState({pagination});
        await this.getPrices();
    }

    createPrice = async (form) => {

        // Если нажали кнопку "Добавить"
        if (!form) {
            this.refDialogForm.current.open({
                initForm: null,
                eventSubmit: this.createPrice.bind(this)
            });

            return null
        }


        this.setState({isShowBackdrop: true});

        const responseCreate = await agent.post(`/smsgoapi/go-prices`, {
            ...form,
            services: (form.services || []).join(","),
            countries: (form.countries || []).join(","),
            users: (form.users || []).join(','),
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return {error: err.response}
        });

        if (responseCreate.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: responseCreate.error?.data?.message || "Ошибка сервера",
                type: NotificationTypes.error
            })

            return null
        }

        this.refDialogForm.current.close();
        this.setState({isShowBackdrop: false});
        Notification({
            message: "Цена успешно создана",
            type: NotificationTypes.success
        });

        await this.getPrices();

    }
    editPrice = async (form, isSubmit) => {

        if (!isSubmit) {
            const initForm = {
                "countries": (form?.countries || []).map((item) => item.external_id),
                "services": (form?.services || []).map((item) => item.external_id),
                "users": (form?.users || []).map((item) => item.id),
                "sort": form?.sort || 0,
                "name": form?.name || "",
                "for_wholesale": form.for_wholesale || false,
                "price": form?.price,
                "provider_id": form.provider_id
            };

            this.refDialogForm.current.open({
                initForm: initForm,
                eventSubmit: this.editPrice.bind(this)
            });

            this.setState({
                editFormId: form.id
            })

            return null
        }

        this.setState({isShowBackdrop: true});

        const users = this.props.users || [];
        const responseEdit = await agent.put(`/smsgoapi/go-prices/${this.state.editFormId}`, {
            ...form,
            services: (form.services || []).join(","),
            countries: (form.countries || []).join(","),
            users: (form.users || []).join(','),
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return {error: err.response}
        });

        if (responseEdit.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: responseEdit.error?.data?.message || "Ошибка сервера",
                type: NotificationTypes.error
            })

            return null
        }

        this.refDialogForm.current.close();
        this.setState({isShowBackdrop: false});

        Notification({
            message: "Цена успешно обновлена",
            type: NotificationTypes.success
        });


        await this.getPrices();

    }
    deletePrice = async (price, isConfirm) => {

        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: `Удаление цены`,
                message: `Вы действительно хотите удалить цену?`,
                labelSuccess: "Да, удалить",
                labelCancel: "Нет, оставить",

                onSuccess: this.deletePrice.bind(this, price, true)
            })

            return null
        }

        this.setState({isShowBackdrop: true});

        const responseDelete = await agent.delete(`/smsgoapi/go-prices/${price.id}`).then((res) => {
            return res.data
        }).catch((err) => {
            return {error: err.response}
        });


        Notification({
            message: "Цена успешно удалена",
            type: NotificationTypes.success
        });

        this.setState({isShowBackdrop: false});

        await this.getPrices();

    }

    render() {
        const {
            prices,
            countries,
            services,

            filter,
            pagination,

            isLoad,
            isShowBackdrop
        } = this.state;
        const {
            users
        } = this.props;

        return (
            <>
                <Container maxWidth="xl">

                    <FilterComponent
                        filter={filter}
                        countries={countries}
                        services={services}
                        users={users}

                        onCreate={() => this.createPrice()}
                        onReset={this.resetFilter}
                        onSearch={this.getPrices}
                        onChange={this.changeFilter}
                    />

                    <TableComponent
                        data={prices}
                        filter={filter}
                        pagination={pagination}

                        isLoad={isLoad}

                        onEdit={this.editPrice}
                        onDelete={this.deletePrice}
                        onChangePagination={this.changePagination}
                        onChangeFilter={this.changeFilter}
                    />

                </Container>


                <DialogFormComponent
                    ref={this.refDialogForm}

                    countries={countries}
                    services={services}
                />

                <DialogConfirmAction
                    ref={this.refDialogConfirmAction}
                />

                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </>
        );
    }
}

export default GoPrices
